import { Id } from "storefront/lib/Id";
import { Department, MENSWEAR } from "storefront/Department";
import { Photo, emptyPhoto } from "storefront/Photo";
import {
  Values as ShippingValues,
  empty as emptyShippingValues,
} from "storefront/Shipping/Values";
import { Trait } from "storefront/Trait";
import { Condition } from "./index";

type AlgoliaListingUser = {
  id: Id;
  username: string;
  totalBoughtAndSold: number;
  avatarUrl: number;
  sellerScore: {
    ratingAverage: number | null | undefined;
    ratingCount: number | null | undefined;
  };
  trustedSeller: boolean;
};

const emptyAlgoliaListingUser: AlgoliaListingUser = {
  id: "",
  username: "",
  totalBoughtAndSold: 0,
  avatarUrl: 0,
  sellerScore: {
    ratingAverage: null,
    ratingCount: null,
  },
  trustedSeller: false,
};

export type AlgoliaListingDesigner = {
  id: Id;
  name: string;
};

const emptyAlgoliaListingDesigner: AlgoliaListingDesigner = {
  id: "",
  name: "",
};

export type AlgoliaListing = {
  id: Id;
  badges: Array<string>;
  bumpedAt: string;
  buynow: boolean;
  category: string;
  categoryPath: string;
  categoryPathSize: string;
  categorySize: string;
  color: string | null;
  condition: Condition | null;
  coverPhoto: Photo;
  createdAt: string;
  createdAtI: number;
  currency: "USD";
  deleted: boolean;
  department: Department;
  description: string;
  designerNames: string;
  designerCount: number;
  designers: Array<AlgoliaListingDesigner>;
  dropped: boolean;
  followerno: number;
  hashtags: Array<string>;
  heatF: number;
  location: string;
  makeoffer: boolean;
  price: number;
  priceDrops: Array<number>;
  priceI: number;
  priceUpdatedAt: string;
  priceUpdatedAtI: number;
  productId: Id | null;
  repostId: Id | null;
  shipping: ShippingValues;
  size: string;
  skuId: Id | null;
  sold: boolean;
  soldAt: string | null;
  soldAtI: number;
  soldPrice: number;
  soldPriceIncludesShipping: boolean | null;
  soldShippingPrice: number | null;
  strata: string;
  title: string;
  traits: Array<Trait>;
  user: AlgoliaListingUser;
};

export const empty: AlgoliaListing = {
  id: "",
  badges: [],
  bumpedAt: "",
  buynow: false,
  category: "",
  categoryPath: "",
  categoryPathSize: "",
  categorySize: "",
  color: null,
  condition: null,
  coverPhoto: emptyPhoto,
  createdAt: "",
  createdAtI: 0,
  currency: "USD",
  deleted: false,
  department: MENSWEAR,
  description: "",
  designerNames: "",
  designerCount: 0,
  designers: [emptyAlgoliaListingDesigner],
  dropped: false,
  followerno: 0,
  hashtags: [],
  heatF: 0,
  location: "",
  makeoffer: false,
  price: 0,
  priceDrops: [],
  priceI: 0,
  priceUpdatedAt: "",
  priceUpdatedAtI: 0,
  productId: null,
  repostId: null,
  shipping: emptyShippingValues,
  size: "",
  skuId: null,
  sold: false,
  soldAt: null,
  soldAtI: 0,
  soldPrice: 0,
  soldPriceIncludesShipping: null,
  soldShippingPrice: null,
  strata: "",
  title: "",
  traits: [],
  user: emptyAlgoliaListingUser,
};
